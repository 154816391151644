import { purchaseUpsell } from '@/modules/upsell/container';
import { PAYPAL_TYPES } from '@/services/constants';
import { Helpers } from '@/services/helpers';
import URLS from '@/services/urls';

import {
  SET_UPSELL_ADDRESS,
  SET_UPSELL_ORDERS,
  SET_UPSELL_PAGE_PROPS,
  SET_UPSELL_PREVIOUS_ORDER_ID,
  SET_UPSELL_STANDALONE,
  SET_UPSELL_STANDALONE_BOUGHT,
  SET_UPSELL_UPDATED_ORDERS,
} from '../mutation-types';

/**
 * Set upsell selected products to has been added to cart
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Product information
 * @return {Object} Contain Product information
 */
export function setUpsellCartProductsAction({ commit }, payload) {
  commit(SET_UPSELL_ORDERS, payload);
}

/**
 * Update upsell products to has been added to cart
 * @param {function} commit - The vuex commit function
 * @param {Object[]} payload - Products information
 * @return {Object[]} Contain Products information
 */
export function updateUpsellCartProductsAction({ commit }, payload) {
  commit(SET_UPSELL_UPDATED_ORDERS, payload);
}

/**
 * Set shipping details form
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Shipping details information
 * @return {Object} Contain shipping details information
 */
export function setShippingDetailsAction({ commit }, payload) {
  commit(SET_UPSELL_ADDRESS, payload);
}

/**
 *  Send upsell orders and shipping details to backend
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {Object} rootState - The vuex root state object
 * @param {Object} payload - { isDonation: [true/false], token: 'paymentToken' }
 * @return {any}
 */
export async function sendUpsellPaymentAction({ state, rootState }, payload = {}) {
  const {
    payment: { paymentPayload, is3DSecureRequired },
  } = rootState;
  const {
    payment: { method, type, transactionId, orderId, variant },
    email,
    firstName,
    lastName,
    locationOrigin,
  } = paymentPayload;
  const { upsellPayload } = state;
  const { orders, payment } = state.upsellPayload;
  const finalPaymentObj = { method, type, ...payment, orderId, variant };

  if (method === PAYPAL_TYPES.global || method === PAYPAL_TYPES.us) {
    finalPaymentObj.transactionId = transactionId;
    delete finalPaymentObj.previousOrderId;

    // If 3d secure is enabled then we send back token
    // and we remove previousOrderId
  } else if (is3DSecureRequired && 'token' in payload) {
    finalPaymentObj.token = payload.token;
    delete finalPaymentObj.previousOrderId;
  }

  let upsellOrders = orders.map((order) => order.id);

  if (
    'isDonation' in payload ||
    'isFastFoodMeal' in payload ||
    'isFastFoodMealPilates' in payload ||
    'isPremiumContent' in payload ||
    'isAlcohol' in payload ||
    'isSweetTreatsMeal' in payload ||
    'is12Month' in payload ||
    'is12MonthLifetime' in payload ||
    'isDayMealPlan' in payload ||
    'isDetox' in payload ||
    'isUpsellYogaAndHealthyBack' in payload
  ) {
    const {
      isDonation,
      isFastFoodMeal,
      isFastFoodMealPilates,
      isPremiumContent,
      isAlcohol,
      isDetox,
      isSweetTreatsMeal,
      is12Month,
      is12MonthLifetime,
      isDayMealPlan,
      isUpsellYogaAndHealthyBack,
    } = payload;

    if (isDonation) upsellOrders = [state.donation];
    if (isFastFoodMeal) upsellOrders = [state.fastFoodMeal];
    if (isFastFoodMealPilates) upsellOrders = [state.fastFoodMealPilates];
    if (isPremiumContent) upsellOrders = [state.premiumContent];
    if (isAlcohol) upsellOrders = [state.alcohol];
    if (isDetox) upsellOrders = [state.detox];
    if (isSweetTreatsMeal) upsellOrders = [state.sweetTreatsMeal];
    if (is12Month) upsellOrders = [state.upsell12Month];
    if (is12MonthLifetime) upsellOrders = [state.upsell12MonthLifetime];
    if (isDayMealPlan) upsellOrders = [state.dayMealPlan];
    if (isUpsellYogaAndHealthyBack) upsellOrders = [state.upsellYogaAndHealthyBack];
  }

  const siteCountry = Helpers.getCountryFromUrl();
  const { uuid } = rootState['have-lead'];
  const upsellPayloadObj = {
    ...upsellPayload,
    orders: upsellOrders,
    payment: finalPaymentObj,
    email,
    firstName,
    lastName,
    siteCountry,
    locationOrigin,
    uuid,
  };

  if (paymentPayload?.fieldsDocumentId) {
    upsellPayloadObj.fieldsDocumentId = paymentPayload.fieldsDocumentId;
  }

  const response = await fetch('/upsell_payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Helpers.cleanEmptyKeysFromObj(upsellPayloadObj)),
  });

  const data = await response.json();

  if (!response.ok) {
    const error = new Error(data.message);
    error.response = response;
    throw error;
  }

  return Helpers.resolvePromise(data);
}

export async function purchaseUpsellAction({ state, rootState }, { onSuccess, onError }) {
  const { uuid } = rootState['have-lead'];
  const siteCountry = Helpers.getCountryFromUrl();
  const { email, firstName, lastName, payment } = rootState.payment.paymentPayload;

  const upsellPayload = {
    firstName,
    lastName,
    email,
    orders: [state.upsellYogaAndHealthyBack],
    payment: {
      method: payment.method,
      type: payment.type,
      orderId: String(payment.orderId),
      previousOrderId: String(payment.orderId),
      transactionId: payment.transactionId,
    },
    siteCountry,
    uuid,
  };

  await purchaseUpsell.execute(upsellPayload, {
    onFailure: (error) => {
      onError(error);
    },
    onSuccess: ({ upsellId }) => {
      onSuccess(upsellId);
    },
  });
}

/**
 *  Send upsell purchase endpoint to create shopify physical products
 * @param {Object} rootState - The vuex root state object
 * @param {String} upsellId - Upsell id would be returned from /upsell_payment
 * @return {any}
 */
export async function sendUpsellPurchaseAction({ rootState }, { upsellId }) {
  const {
    payment: {
      paymentPayload: { email, payment },
    },
  } = rootState;

  const { variant } = payment;
  const data = await this.$api.$post(URLS.UPSELL_PURCHASE, { variant, email, upsellId });
  return Helpers.resolvePromise(data);
}

/**
 * Set upsell component page props an example: {pageType: "wp", class: "upsell--wp"} || { vueProp: vueValue }
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Upsell page props
 * @return {Object} Upsell page props
 */
export function setUpsellPagePropsAction({ commit }, payload) {
  commit(SET_UPSELL_PAGE_PROPS, payload);
}

/**
 * Set upsell PreviousOrderId from sticky
 * @param {function} commit - The vuex commit function
 * @param {String} payload - PreviousOrderId value
 * @return {String} Upsell PreviousOrderId
 */
export function setUpsellPreviousOrderIdAction({ commit }, payload) {
  commit(SET_UPSELL_PREVIOUS_ORDER_ID, payload);
}

export function setUpsellStandaloneAction({ commit }, payload) {
  commit(SET_UPSELL_STANDALONE, payload);
}

export function setUpsellStandaloneBoughtAction({ commit }, payload) {
  commit(SET_UPSELL_STANDALONE_BOUGHT, payload);
}
