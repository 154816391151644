import { getCheckoutIdPathByRegionAndProduct } from '@/modules/checkout/container';
import { getProductVerticalAndTypeByProduct } from '@/modules/product/container';
import { EXPERIMENT_NAMES } from '@/services/constants';

import { SET_CHECKOUT_ID } from '../mutation-types';

export function fetchCheckoutId({ rootState, commit, dispatch }) {
  const { product } = rootState['quiz'];

  const { vertical, type } = getProductVerticalAndTypeByProduct.execute({ product });

  const pricingCheckoutABTest = this.$exp?.activeExperiments.find(({ experiment }) =>
    [
      EXPERIMENT_NAMES.PRICING_PILATES_WEEKLY_NEW_CHECKOUT,
      EXPERIMENT_NAMES.PILATES_CHALLENGE_AND_NUTRITION_YOGA_PRICE_INCREASE_AU,
      EXPERIMENT_NAMES.PILATES_CHALLENGE_AND_NUTRITION_PRICE_INCREASE,
      EXPERIMENT_NAMES.PILATES_YOGA_HEALTH_PRICE_INCREASE,
    ].includes(experiment?.name),
  );

  getCheckoutIdPathByRegionAndProduct.execute(
    {
      vertical,
      type,
      pricingModel: product.pricingModel,
      region: this.$countryCode,
      brand: product.brand,
      priceVariant: pricingCheckoutABTest?.variants[0]?.pricing || null,
      variation: product?.checkoutVariation || null,
    },
    {
      onSuccess: (checkoutId) => {
        commit(SET_CHECKOUT_ID, checkoutId);
      },
      onFailure: (error) => {
        dispatch(
          'logger/logEventWithQuizMeta',
          {
            message: 'New checkout ID not found',
            tag: 'new-checkout-id-not-found',
            error,
            meta: {
              checkout: {
                vertical,
                type,
                id: 'not-found',
                pricingModel: product.pricingModel,
                region: this.$countryCode,
                brand: product.brand,
                priceVariant: pricingCheckoutABTest?.variants[0]?.pricing || null,
                variation: product?.checkoutVariation || null,
              },
            },
          },
          { root: true },
        );

        commit(SET_CHECKOUT_ID, null);
      },
    },
  );
}
