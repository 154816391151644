import * as Sentry from '@sentry/browser';

import { Helpers } from '../services/helpers';

export const getUtmObject = () => {
  if (process.client) {
    try {
      return JSON.parse(window?.sessionStorage.getItem('utmObject')) || {};
    } catch {
      Sentry.captureException(new Error('Error occurred during getting items from sessionStorage'));
      return {};
    }
  }
};

export const setUtmObject = (utmObject) => {
  try {
    const cleanUtmObject = Helpers.cleanEmptyKeysFromObj(utmObject);
    window?.sessionStorage.setItem('utmObject', JSON.stringify(cleanUtmObject));
  } catch {
    Sentry.captureException(new Error('Error occurred during setting items from sessionStorage'));
    console.error('Cannot set UTM object');
  }
};
