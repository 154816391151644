export default async function (context, inject) {
  let jsonData = null;
  try {
    const response = await context.$axios.get(context.$config.abTestingKv, { timeout: 3000 });
    jsonData = response.data;

    inject('kvExperiments', Array.isArray(jsonData) ? jsonData : []);
  } catch (error) {
    context.$sentry.captureException(error);

    inject('kvExperiments', []);
  }
}
