import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';
import { REGIONS } from '@/modules/region/domain/region';

/**
 * List of global constants
 * @return {Object} that contain all global constants
 */
export const PAYPAL_TYPES = Object.freeze({
  global: 'paypal',
  us: 'paypal_us',
});

export const STICKY = 'sticky';

export const APPLE_PAY_BRAINTREE = 'apple_pay';

export const APPLE_PAY_CHECKOUT = 'apple_pay_checkout';

export const UPSELL_TYPES = Object.freeze({
  fastFoodMeal: 'fastFoodMeal',
  fastFoodMealPilates: 'fastFoodMealPilates',
  sweetTreatsMeal: 'sweetTreatsMeal',
  premiumContent: 'premiumContent',
  alcohol: 'alcohol',
  dayMealPlan: 'dayMealPlan',
  detox: 'detox',
  '12Month': '12Month',
  '12MonthLifetime': '12MonthLifetime',
  yogaAndHealthyBack: 'yogaAndHealthyBack',
});

export const MEASUREMENTS_ATTRIBUTES = Object.freeze({
  imperial: 'imperial',
  metric: 'metric',
});

export const COOKIES = Object.freeze({
  token: 'token',
  lang: 'rhLang',
  userAttrs: 'userAttrs',
  uniquePaymentOptionsPageEvent: 'uniquePaymentOptionsPageEvent',
  uniqueLeadCreatedEvent: 'uniqueLeadCreatedEvent',
  uniqueConfirmationPageEvent: 'uniqueConfirmationPageEvent',
});

export const QUIZ_PRODUCT_TYPES = Object.freeze({
  nutritionMenopause: 'nutrition-menopause',
  nutritionKeto: 'nutrition-keto',
  fitnessPilates: 'fitness-pilates',
  nutritionGeneric: 'nutrition-generic',
  fitnessYoga: 'fitness-yoga',
  fitnessYogaMenopause: 'fitness-yoga-menopause',
  fitnessSomaticYoga: 'fitness-somatic-yoga',
  fitnessSomaticExercises: 'fitness-somatic-exercises',
  fakeFitOver40: 'fake-fit-over-40',
  fitOver40: 'fit-over-40',
});

export const PRICING_MODELS = Object.freeze({
  monthly: 'Monthly',
  weekly: 'Weekly',
});

export const DEFAULT_PRODUCT_TYPES = Object.freeze([
  {
    194: 'fitness-pilates',
    tourId: 194,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    193: 'fitness-pilates',
    tourId: 193,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    192: 'nutrition-menopause',
    tourId: 192,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    0: 'nutrition-menopause',
    tourId: 0,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    205: 'nutrition-keto',
    tourId: 205,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    203: 'fitness-pilates',
    tourId: 203,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    209: 'fitness-pilates',
    tourId: 209,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    191: 'nutrition-menopause',
    tourId: 191,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    190: 'nutrition-menopause',
    tourId: 190,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    210: 'fitness-yoga-menopause',
    tourId: 210,
    productType: 'fitness-yoga-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    196: 'nutrition-menopause',
    tourId: 196,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    204: 'nutrition-menopause',
    tourId: 204,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    198: 'fitness-pilates',
    tourId: 198,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    199: 'fitness-pilates',
    tourId: 199,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    200: 'fitness-pilates',
    tourId: 200,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    201: 'fitness-pilates',
    tourId: 201,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    202: 'fitness-pilates',
    tourId: 202,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    189: 'nutrition-menopause',
    tourId: 189,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    212: 'nutrition-keto',
    tourId: 212,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    219: 'fitness-somatic-yoga',
    tourId: 219,
    productType: 'fitness-somatic-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    206: 'fitness-pilates',
    tourId: 206,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    207: 'fitness-pilates',
    tourId: 207,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    208: 'fitness-pilates',
    tourId: 208,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    188: 'nutrition-keto',
    tourId: 188,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    217: 'nutrition-menopause',
    tourId: 217,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    227: 'fitness-pilates',
    tourId: 227,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    220: 'fitness-somatic-exercises',
    tourId: 220,
    productType: 'fitness-somatic-exercises',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    240: 'fitness-pilates',
    tourId: 240,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    218: 'nutrition-menopause',
    tourId: 218,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    239: 'fitness-pilates',
    tourId: 239,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    213: 'fitness-pilates',
    tourId: 213,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    222: 'fitness-pilates',
    tourId: 222,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    223: 'fitness-yoga',
    tourId: 223,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    224: 'fitness-yoga',
    tourId: 224,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    183: 'fitness-calisthenics',
    tourId: 183,
    productType: 'fitness-calisthenics',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    232: 'nutrition-menopause',
    tourId: 232,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    234: 'fitness-yoga',
    tourId: 234,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    164: 'nutrition-menopause',
    tourId: 164,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    165: 'nutrition-menopause',
    tourId: 165,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    166: 'nutrition-menopause',
    tourId: 166,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    167: 'nutrition-menopause',
    tourId: 167,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    169: 'nutrition-menopause',
    tourId: 169,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    168: 'nutrition-menopause',
    tourId: 168,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    171: 'nutrition-menopause',
    tourId: 171,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    170: 'nutrition-menopause',
    tourId: 170,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    172: 'nutrition-menopause',
    tourId: 172,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    142: 'nutrition-menopause',
    tourId: 142,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    150: 'nutrition-keto',
    tourId: 150,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    148: 'nutrition-menopause',
    tourId: 148,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    159: 'nutrition-menopause',
    tourId: 159,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    160: 'nutrition-menopause',
    tourId: 160,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    161: 'nutrition-keto',
    tourId: 161,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    162: 'nutrition-keto',
    tourId: 162,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    175: 'fitness-yoga',
    tourId: 175,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    179: 'fitness-pilates',
    tourId: 179,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    180: 'fitness-pilates',
    tourId: 180,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    185: 'nutrition-menopause',
    tourId: 185,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    186: 'nutrition-menopause',
    tourId: 186,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    187: 'nutrition-menopause',
    tourId: 187,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    152: 'fitness-pilates',
    tourId: 152,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    151: 'fitness-pilates',
    tourId: 151,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    154: 'fitness-pilates',
    tourId: 154,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    144: 'fitness-pilates',
    tourId: 144,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    130: 'nutrition-menopause',
    tourId: 130,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    135: 'nutrition-menopause',
    tourId: 135,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    124: 'nutrition-menopause',
    tourId: 124,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    125: 'nutrition-generic',
    tourId: 125,
    productType: 'nutrition-generic',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    177: 'fitness-pilates',
    tourId: 177,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    173: 'nutrition-menopause',
    tourId: 173,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    157: 'nutrition-menopause',
    tourId: 157,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    156: 'fitness-pilates',
    tourId: 156,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    155: 'fitness-pilates',
    tourId: 155,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    149: 'fitness-pilates',
    tourId: 149,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    147: 'fitness-pilates',
    tourId: 147,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    131: 'fitness-pilates',
    tourId: 131,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    133: 'fitness-pilates',
    tourId: 133,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    181: 'nutrition-menopause',
    tourId: 181,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    182: 'nutrition-menopause',
    tourId: 182,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    99: 'nutrition-menopause',
    tourId: 99,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    163: 'nutrition-menopause',
    tourId: 163,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    178: 'fitness-pilates',
    tourId: 178,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    163: 'nutrition-menopause',
    tourId: 163,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    233: 'fitness-pilates',
    tourId: 233,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    145: 'fitness-pilates',
    tourId: 145,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    140: 'nutrition-menopause',
    tourId: 140,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    228: 'fitness-pilates',
    tourId: 228,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    231: 'nutrition-menopause',
    tourId: 231,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    229: 'fitness-pilates',
    tourId: 229,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    230: 'fitness-pilates',
    tourId: 230,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    174: 'fitness-yoga',
    tourId: 174,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    242: 'fitness-pilates',
    tourId: 242,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'FitOver40',
    checkoutVariation: null,
  },
  {
    246: 'nutrition-menopause',
    tourId: 246,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    247: 'nutrition-menopause',
    tourId: 247,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    176: 'fitness-yoga',
    tourId: 176,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    139: 'fitness-pilates',
    tourId: 139,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'pilates-now',
  },
  {
    216: 'nutrition-menopause',
    tourId: 216,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    215: 'fitness-yoga',
    tourId: 215,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    214: 'fitness-pilates',
    tourId: 214,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    211: 'fitness-pilates',
    tourId: 211,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    184: 'fitness-yoga',
    tourId: 184,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    248: 'nutrition-menopause',
    tourId: 248,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    249: 'nutrition-menopause',
    tourId: 249,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    250: 'nutrition-menopause',
    tourId: 250,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    221: 'fitness-pilates',
    tourId: 221,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    251: 'fitness-yoga',
    tourId: 251,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    252: 'fitness-yoga',
    tourId: 252,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    237: 'fitness-pilates',
    tourId: 237,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    236: 'nutrition-menopause',
    tourId: 236,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    238: 'fitness-yoga',
    tourId: 238,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    253: 'fitness-pilates',
    tourId: 253,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    254: 'fitness-pilates',
    tourId: 254,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    255: 'fitness-yoga',
    tourId: 255,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    256: 'fitness-pilates',
    tourId: 256,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    257: 'nutrition-menopause',
    tourId: 257,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
]);

export const QUIZ_VARIANTS = Object.freeze({
  DEFAULT: '0',
  NUTRITION: '99',
  LEAN_BODY: '121',
  TRIAL: '124',
  BEST_BODY: '129',
  TRIAL_GET_BACK_INTO_SHAPE: '130',
  BODY_28_DAY_CHALLENGE: '132',
  BEST_BODY_OVER_40: '134',
  YOUR_BEST_BODY: '137',
  BEST_LEAN_BODY: '138',
  PILATES_NOW: '139',
  BODY_NUTRITION: '142',
  PILATES_MONTHLY: '144',
  PILATES_28_DAY_CHALLENGE_MONTHLY: '145',
  PILATES_NEW_LANDER: '147',
  WEIGHT_LOSS_MONTHLY: '148',
  WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN: '231',
  PILATES_28_DAY_CHALLENGE_NEW_LANDER: '149',
  NUTRITION_KETO: '150',
  NUTRITION_KETO_MEAL: '205',
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: '155',
  PILATES_LEGAL_DISCLAIMER: '156',
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: '159',
  HEALTH_NO_SUGAR_CHALLENGE: '160',
  NUTRITION_KETO_WEEKLY: '161',
  NUTRITION_KETO_AGE: '162',
  FAST_WEIGHT_LOSS: '164',
  MACRO_DIET: '165',
  OPTIMAL_WEIGHT: '166',
  WEIGHT_LOSS_OVER_40: '167',
  WEIGHT_LOSS_WITHOUT_FASTING: '168',
  HEALTH_FOOD_IMPACT: '169',
  METABOLIC_RENEWAL: '170',
  CHOLESTEROL_CONTROL: '171',
  WEIGHT_LOSS_SECRET: '172',
  YOGA_28_DAY_CHALLENGE: '175',
  YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1: '251',
  YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2: '252',
  PILATES_NEW_CHECKOUT: '179',
  PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT: '180',
  NUTRITION_WEEKLY_START: '187',
  NUTRITION_KETO_WEEKLY_AGE: '188',
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: '203',
  YOGA_MENOPAUSE_CHALLENGE: '210',
  NUTRITION_KETO_MEAL_WEEKLY: '212',
  SOMATIC_YOGA: '219',
  SOMATIC_EXERCISES: '220',
  NUTRITION_MONTHLY_GENERIC_US: '232',
  PILATES_28_DAY_CHALLENGE_GENERIC_US: '233',
  YOGA_28_DAY_CHALLENGE_GENERIC_US: '234',
  NUTRITION_MONTHLY_GENERIC_OTHERS: '236',
  PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS: '237',
  YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS: '238',
  PILATES_28_DAY_CHAIR_CHALLENGE: '239',
  PILATES_28_DAY_FLAT_BELLY: '240',
  WALL_PILATES: '242',
  PILATES_28_DAY: '253',
  PILATES_28_D: '254',
  YOGA_28_DAY_CHALLENGE_META_CHANGES: '255',
  PILATES_28_DAY_CHALLENGE_META_CHANGES: '256',
  WEIGHT_LOSS_MONTHLY_META_CHANGES: '257',
});

export const LANDING_PAGE_IDS = Object.freeze({
  DEFAULT: 0,
  BEST_BODY: 2,
  BODY_28_DAY_CHALLENGE: 3,
  CHOLESTEROL_CONTROL: 4,
  FAST_WEIGHT_LOSS_FOR_WOMEN: 5,
  LEAN_BODY: 8,
  MACRO_DIET_FOR_WOMEN: 9,
  METABOLIC_RENEWAL_FOR_WOMEN: 12,
  OPTIMAL_WEIGHT_PROGRAM: 13,
  PILATES: 14,
  PILATES_28_DAY_CHALLENGE: 15,
  TRIAL: 16,
  TRIAL_GET_BACK_INTO_SHAPE: 17,
  WEIGHT_LOSS: 19,
  WEIGHT_LOSS_OVER_40: 20,
  WEIGHT_LOSS_SECRET: 21,
  WEIGHT_LOSS_WITHOUT_FASTING: 22,
  WOMENS_HEALTH_FOOD_IMPACT: 24,
  BEST_BODY_OVER_40: 25,
  YOUR_BEST_BODY: 26,
  BEST_LEAN_BODY: 28,
  PILATES_NOW: 29,
  BODY_NUTRITION: 31,
  PILATES_MONTHLY: 32,
  PILATES_28_DAY_CHALLENGE_MONTHLY: 33,
  NUTRITION_KETO: 35,
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: 40,
  PILATES_LEGAL_DISCLAIMER: 41,
  HEALTH_NO_SUGAR_CHALLENGE: 42,
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: 43,
  NUTRITION_KETO_WEEKLY: 44,
  NUTRITION_KETO_AGE: 45,
  NUTRITION_WEEKLY_START: 46,
  YOGA_28_DAY_CHAIR_YOGA_CHALLENGE: 47,
  NUTRITION_KETO_WEEKLY_AGE: 48,
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: 54,
  YOGA_MENOPAUSE_CHALLENGE: 62,
  NUTRITION_KETO_MEAL_WEEKLY: 64,
  NUTRITION_KETO_MEAL: 65,
  SOMATIC_YOGA: 69,
  SOMATIC_EXERCISES: 70,
  NUTRITION_MONTHLY_GENERIC_US: 71,
  NUTRITION_MONTHLY_GENERIC_OTHERS: 72,
  PILATES_28_DAY_CHALLENGE_US: 73,
  PILATES_28_DAY_CHALLENGE_OTHERS: 74,
  YOGA_28_DAY_CHALLENGE_GENERIC_US: 75,
  YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS: 76,
  WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN: 77,
  WALL_PILATES: 78,
  PILATES_28_DAY: 79,
  PILATES_28_D: 80,
});

export const EXPERIMENT_NAMES = Object.freeze({
  PILATES_28_DAY_CHALLENGE_META_CHANGES: 'pilates-28-day-challenge-meta-changes',
  YOGA_28_DAY_CHALLENGE_META_CHANGES: 'yoga-28-day-challenge-meta-changes',
  PRICING_PILATES_WEEKLY_NEW_CHECKOUT: 'pricing-pilates-weekly-new-checkout',
  YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES: 'yoga-28-day-challenge-full-body-before-after-images',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_TEST_DIFFERENT_TITLE: 'pilates-28-day-challenge-checkout-test-different-title',
  FITNESS_PILATES_SECRET_GIFT_MESSAGE: 'fitness-pilates-secret-gift-message',
  PILATES_CHALLENGE_AND_NUTRITION_PRICE_INCREASE: 'pilates-challenge-and-nutrition-price-increase',
  PILATES_CHALLENGE_AND_NUTRITION_YOGA_PRICE_INCREASE_AU: 'pilates-challenge-and-nutrition-yoga-price-increase-au',
  RH_NUTRITION_QUIZ_CHECKOUT_COPY_CHANGES_META_ISSUE: 'rh-nutrition-quiz-checkout-copy-changes-meta-issue',
  PILATES_YOGA_HEALTH_PRICE_INCREASE: 'pilates-yoga-health-price-increase',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_REMOVE_PAYPAL_OPTION_IN_BR:
    'pilates-28-day-challenge-checkout-remove-paypal-option-in-br',
  PILATES_28_DAY_CHALLENGE_CHECKOUT_MONEY_BACK_GUARANTEE: 'pilates-28-day-challenge-checkout-money-back-guarantee',
});

export const STATES_LIST = Object.freeze([
  { label: 'Alabama', value: 'alabama' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'Arizona', value: 'arizona' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'Florida', value: 'florida' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Hawaii', value: 'hawaii' },
  { label: 'Idaho', value: 'idaho' },
  { label: 'Illinois', value: 'illinois' },
  { label: 'Indiana', value: 'indiana' },
  { label: 'Iowa', value: 'iowa' },
  { label: 'Kansas', value: 'kansas' },
  { label: 'Kentucky', value: 'kentucky' },
  { label: 'Louisiana', value: 'louisiana' },
  { label: 'Maine', value: 'maine' },
  { label: 'Maryland', value: 'maryland' },
  { label: 'Massachusetts', value: 'massachusetts' },
  { label: 'Michigan', value: 'michigan' },
  { label: 'Minnesota', value: 'minnesota' },
  { label: 'Mississippi', value: 'mississippi' },
  { label: 'Missouri', value: 'missouri' },
  { label: 'Montana', value: 'montana' },
  { label: 'Nebraska', value: 'nebraska' },
  { label: 'Nevada', value: 'nevada' },
  { label: 'New Hampshire', value: 'new hampshire' },
  { label: 'New Jersey', value: 'new jersey' },
  { label: 'New Mexico', value: 'new mexico' },
  { label: 'New York', value: 'new york' },
  { label: 'North Carolina', value: 'north carolina' },
  { label: 'North Dakota', value: 'north dakota' },
  { label: 'Ohio', value: 'ohio' },
  { label: 'Oklahoma', value: 'oklahoma' },
  { label: 'Oregon', value: 'oregon' },
  { label: 'Pennsylvania', value: 'pennsylvania' },
  { label: 'Rhode Island', value: 'rhode island' },
  { label: 'South Carolina', value: 'south carolina' },
  { label: 'South Dakota', value: 'south dakota' },
  { label: 'Tennessee', value: 'tennessee' },
  { label: 'Texas', value: 'texas' },
  { label: 'Utah', value: 'utah' },
  { label: 'Vermont', value: 'vermont' },
  { label: 'Virginia', value: 'virginia' },
  { label: 'Washington', value: 'washington' },
  { label: 'West Virginia', value: 'west virginia' },
  { label: 'Wisconsin', value: 'wisconsin' },
  { label: 'Wyoming', value: 'wyoming' },
]);

export const RESTRICTED_STATES = Object.freeze(['mississippi']);

export const CURRENCY_MAP = Object.freeze({
  USD: '$',
  GBP: '£',
  EUR: '€',
  AUD: 'AUD',
  CAD: 'CAD',
  NZD: 'NZD',
  ZAR: 'ZAR',
});

export const RH_IMAGES_HOSTS = Object.freeze({
  LOCALHOST: 'http://localhost:3001',
  STAGING: 'https://staging-static.reverse.health',
  PRODUCTION: 'https://static.reverse.health',
});

export const F040_IMAGES_HOSTS = Object.freeze({
  LOCALHOST: 'http://localhost:3001',
  STAGING: 'https://staging-static.fitover40.health',
  PRODUCTION: 'https://static.fitover40.health',
});

export const MAGIC_LINK_VERSION = 2;

export const SUBDOMAIN_BY_BRAND = Object.freeze({
  [PROJECT_BRANDS.reverse]: 'reverse.health',
  [PROJECT_BRANDS.fitover40]: 'fitover40.health',
});

export const REGION_LOCALE_PILATES_28_DAY_CHALLENGE = Object.freeze({
  [REGIONS.us]: ['en'],
  [REGIONS.au]: ['en'],
  [REGIONS.uk]: ['en'],
  [REGIONS.join]: ['en'],
  [REGIONS.eu]: ['en', 'de', 'fr'],
  [REGIONS.latam]: ['es'],
  [REGIONS.br]: ['pt'],
});
