import { EXPERIMENT_NAMES } from '@/services/constants.js';

export default {
  name: EXPERIMENT_NAMES.PILATES_YOGA_HEALTH_PRICE_INCREASE,
  includedRoutes: [
    '/quiz/enter-email-new/',
    '/quiz/enter-email-new',
    '/quiz/ready-to-commit/',
    '/quiz/ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/ready-to-commit-yoga/',
    '/quiz/ready-to-commit-yoga',
    '/quiz/authority-related-testimonials-body-nutrition/',
    '/quiz/authority-related-testimonials-body-nutrition',
  ],
  allocateOnClient: true,
  variants: [
    {
      pricing: 0,
    },
    {
      pricing: 1,
    },
    {
      pricing: 2,
    },
  ],
};
