// import dummyOfferTest from './dummy-offer-test';
// import dummyTourTest from './dummy-tour-test';
import pilates28DayChallengeCheckoutMoneyBackGuarantee from './pilates-28-day-challenge-checkout-money-back-guarantee';
import pilates28DayChallengeCheckoutRemovePaypalOptionInBr from './pilates-28-day-challenge-checkout-remove-paypal-option-in-br';
import pilates28DayChallengeCheckoutTestDifferentTitle from './pilates-28-day-challenge-checkout-test-different-title';
import pilates28DayChallengeMetaChanges from './pilates-28-day-challenge-meta-changes';
import pilatesChallengeAndNutritionPriceIncrease from './pilates-challenge-and-nutrition-price-increase';
import pilatesChallengeAndNutritionYogaPriceIncreaseAu from './pilates-challenge-and-nutrition-yoga-price-increase-au';
import pilatesYogaHealthPriceIncrease from './pilates-yoga-health-price-increase';
import yoga28DayChallengeFullBodyBeforeAfterImages from './yoga-28-day-challenge-full-body-before-after-images';
import yoga28DayChallengeMetaChanges from './yoga-28-day-challenge-meta-changes';

const tests = [
  // Dummy experiments
  // dummyOfferTest,
  // dummyTourTest,

  yoga28DayChallengeFullBodyBeforeAfterImages,
  pilates28DayChallengeCheckoutTestDifferentTitle,
  pilates28DayChallengeMetaChanges,
  yoga28DayChallengeMetaChanges,
  pilatesChallengeAndNutritionPriceIncrease,
  pilatesChallengeAndNutritionYogaPriceIncreaseAu,
  pilatesYogaHealthPriceIncrease,
  pilates28DayChallengeCheckoutRemovePaypalOptionInBr,
  pilates28DayChallengeCheckoutMoneyBackGuarantee,
];

export default tests;
