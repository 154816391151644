export function getGtmUrlBuilder(domain) {
  const domainToUrl = {
    'fitover40.health': 'https://load.d.fitover40.health',
    'staging.fitover40.health': 'https://load.d.fitover40.health',
    'reverse.health': 'https://load.d.reverse.health',
    'staging.reverse.health': 'https://load.d.reverse.health',
  };

  return domainToUrl[domain] || domainToUrl['reverse.health'];
}

/**
 * Checks if a "step_completed" event already exists in the dataLayer.
 *
 * @param {string} step_id - The step identifier.
 * @param {number} tour_step_number - The step number in the tour.
 * @returns {boolean} - Returns `true` if the event already exists, `false` otherwise.
 */
export function isStepCompletedDuplicated(step_id, tour_step_number) {
  if (!step_id || typeof tour_step_number !== 'number') {
    return false;
  }

  const dataLayer = window.dataLayer ?? [];

  return Boolean(
    dataLayer.some(
      (event) =>
        Object.is(event.event, 'step_completed') &&
        Object.is(event.step_id, step_id) &&
        Object.is(event.tour_step_number, tour_step_number),
    ),
  );
}
